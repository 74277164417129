<template>
  <div class="onboarding-done-modal-content">
    <div class="heading-4 heading-3-mobile">{{ title }}</div>
    <div class="text-lg subheading">{{ subtitle }}</div>

    <div class="items" v-if="hasRelatives">
      <div class="item">
        <div class="heading-5 heading-4-mobile">🗃️</div>
        <div class="text-lg text-lg-mobile">Matching your tree with database...</div>
      </div>
      <div class="item">
        <div class="heading-5 heading-4-mobile">📨</div>
        <div class="text-lg text-lg-mobile">You'll get record matches by email</div>
      </div>
      <div class="item">
        <div class="heading-5 heading-4-mobile tree-icon">🌳</div>
        <div class="text-lg text-lg-mobile">Expand your tree for more matches!</div>
      </div>
    </div>
    <div class="items" v-else>
      <div class="item">
        <div class="heading-5 heading-4-mobile tree-icon">🌳</div>
        <div class="text-lg text-lg-mobile">Add relatives to your family tree</div>
      </div>
      <div class="item">
        <div class="heading-5 heading-4-mobile">🗃️</div>
        <div class="text-lg text-lg-mobile">RecordFinder™ searches our database for you</div>
      </div>
      <div class="item">
        <div class="heading-5 heading-4-mobile">📨</div>
        <div class="text-lg text-lg-mobile">You'll get record matches by email</div>
      </div>
    </div>

    <div class="view-tree-button-container">
      <a class="view-tree-button" @click="hideModal"
        ><span>View Tree</span><arrow-right-icon :size="18"></arrow-right-icon
      ></a>
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight';

export default {
  props: {
    hasRelatives: Boolean,
  },
  computed: {
    title() {
      return 'RecordFinder™';
    },
    subtitle() {
      return this.hasRelatives ? 'Your progress has been saved' : '';
    },
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },
  },
  components: {ArrowRightIcon},
  name: 'OnboardingDoneModalContent',
};
</script>

<style lang="scss" scoped>
.onboarding-done-modal-content {
  padding: 24px;

  .heading-4 {
    text-align: center;
  }
  .subheading {
    color: $neutral-500;
    text-align: center;
  }

  .items {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin: 20px 0 20px;

    .item {
      display: flex;
      align-items: flex-start;
      column-gap: 12px;

      .heading-5 {
        width: 26px;
      }
    }
  }

  .view-tree-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .view-tree-button {
      display: flex;
      align-items: center;
    }
  }

  .arrow-right-icon {
    margin-left: 8px;
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    min-width: 440px;
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    padding: 16px;
    .items {
      margin: 20px 0 24px;
    }
  }
}
</style>
