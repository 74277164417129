<template>
  <div class="family-tree-clan-page" v-if="pageLoading">
    <div class="readable_content">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
  </div>
  <div class="family-tree-clan-page" v-else-if="pageNotFound">
    <page-not-found></page-not-found>
  </div>
  <div class="family-tree-clan-page" v-else>
    <div class="readable_content mobile_full_width">
      <div class="controls">
        <router-link :to="allClansRoute"><chevron-left-icon :size="16" />All Clans</router-link>
        <familytree-sharing-popover
          v-if="familyTreeDetailsState.object_id"
          :tree-id="treeId"
          :tree-name="familyTreeDetailsState.name"
          :is-tree-public="familyTreeDetailsState.is_public"
          :tree-members="familyTreeDetailsState.tree_members"
          :is-owner="familyTreeDetailsState.is_owner"
          :share-link="treeShareLink"
          class="tree-action item"
          @members-change="onShareMembersChange"
          @is-public-change="onSharePublicChange"
        ></familytree-sharing-popover>
      </div>
      <stand-out-block class="light heading-block">
        <div class="clan-data-container">
          <div>
            <h5>{{ clanName }} Family</h5>
            <div class="sub-title">{{ spellingsFromTree }}</div>
          </div>
        </div>
      </stand-out-block>

      <div class="item-block zupus-item-block">
        <div class="header">
          <div class="heading-6">Zupus (Family Tree Books)</div>
        </div>
        <div class="content" v-if="savedClanPedigreeListLoadingState">
          <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
        </div>
        <div class="content" v-else-if="savedClanPedigreeListState.length">
          <my-zupu-item
            v-for="ownedZupu in savedClanPedigreeListState"
            :key="ownedZupu.clan_pedigree.object_id"
            :owned-zupu="ownedZupu"
            :removable="familyTreeDetailsState.is_owner"
            :refetch-list="fetchSavedZupus"
          ></my-zupu-item>
          <div class="pagination-container">
            <base-pagination :meta="savedClanPedigreeListMetaState" @onSwitchPage="fetchSavedZupus"></base-pagination>
          </div>
        </div>
        <about-zupus v-else>
          <template v-slot:search-link>
            <router-link :to="searchZupusRoute" class="bold" v-if="zupusCount"
              >View {{ zupusCount.toLocaleString('en') }} {{ clanName }} records in our database
              <img class="db-logo" :src="$getAsset('/assets/mcr-db-badge-logo.png')" alt="Database Logo"
            /></router-link>
          </template>
        </about-zupus>
      </div>

      <div class="item-block">
        <div class="header">
          <div class="heading-6">Places</div>
        </div>
        <div class="text-md text-lg-mobile content">
          <div class="bold" v-if="overseasPlaces.length">Migrated to</div>
          <div class="places-list" v-if="overseasPlaces.length">
            <div v-for="place in overseasPlaces">
              <router-link :to="getPlaceDetailRoute(place.id)">{{ place.address_en }}</router-link>
            </div>
          </div>
          <div class="bold" v-if="ancestralPlaces.length">Ancestral Homes</div>
          <div class="places-list" v-if="ancestralPlaces.length">
            <div v-for="place in ancestralPlaces">
              <router-link :to="getPlaceDetailRoute(place.id)">{{ place.address_en }}</router-link>
            </div>
          </div>
          <div class="bold" v-if="treeClanMetaState.place_names.length">Other Locations</div>
          <div class="places-list" v-if="treeClanMetaState.place_names.length">
            <div v-for="placeName in treeClanMetaState.place_names">{{ placeName }}</div>
          </div>
        </div>
      </div>

      <div class="item-block persons-item-block">
        <div class="header">
          <div class="heading-6">{{ membersLabel }}</div>
        </div>
        <div class="text-md text-lg-mobile content">
          <div class="persons">
            <div v-for="person in treeClanMetaState.persons" class="relative-item-wrapper">
              <relative-item
                :person="person"
                :profile-picture="treeClanMetaState.profile_pictures[person.object_id] || ''"
                :spouse-meta="{}"
                :meta-editable="false"
                :key="person.object_id"
              ></relative-item>
            </div>
          </div>
        </div>
      </div>

      <div class="item-block">
        <div class="header">
          <div class="heading-6">Surname Information</div>
        </div>
        <div class="text-md text-lg-mobile content">
          <router-link :to="{name: 'search-tool-surname-detail', query: {word: clanName}}" class="bold"
            >Learn more about the {{ clanName }} surname</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import StandOutBlock from '@common/elements/layouts/StandOutBlock.vue';
import {TAB_ID_SOURCES} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {isChineseText} from '@common/utils/utils';
import {getPlaceDetailRoute, getTreeClanDetailRoute} from '@common/utils/utils.routes';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft';
import {mapGetters} from 'vuex';

import AboutZupus from '@/components/common/AboutZupus.vue';
import MyZupuItem from '@/components/common/tree/MyZupuItem';
import RelativeItem from '@/components/common/tree/RelativeItem';

import FamilytreeSharingPopover from '@/components/modules/familyTree/controls/familytreeSharingPopover.vue';
import PageNotFound from '@/components/page.not.found';

export default {
  data() {
    return {
      pageLoading: true,
      zupusCount: 0,
      pageNotFound: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters([
      'treeClanMetaState',
      'familyTreeDetailsState',
      'savedClanPedigreeListState',
      'savedClanPedigreeListMetaState',
      'savedClanPedigreeListLoadingState',
    ]),
    clanName() {
      return this.$route.params.name;
    },
    treeId() {
      return this.$route.params.id;
    },
    allClansRoute() {
      return {name: 'familytree-clans', params: {id: this.treeId}};
    },
    searchZupusRoute() {
      return {
        name: 'search-all-records',
        query: {tab: TAB_ID_SOURCES, category_id: sourceTypeCategories.CATEGORY_ZUPUS, clan_name: this.clanName},
      };
    },
    spellingsFromTree() {
      const surnames = this.treeClanMetaState.persons
        .map(p => p.surnames.map(s => s.value).filter(surname => !isChineseText(surname)))
        .flat();
      return [...new Set(surnames)].join(', ');
    },
    membersLabel() {
      const count = this.treeClanMetaState.persons.length;
      return `${count.toLocaleString('en')} ${count === 1 ? 'Member' : 'Members'}`;
    },
    treeShareLink() {
      let resolved = this.$router.resolve(getTreeClanDetailRoute(this.treeId, this.clanName));
      return decodeURI(`${window.location.host}${resolved.href}`);
    },
    overseasPlaces() {
      return this.treeClanMetaState.places.filter(p => !p.is_ancestral_place);
    },
    ancestralPlaces() {
      return this.treeClanMetaState.places.filter(p => p.is_ancestral_place);
    },
  },
  methods: {
    getPlaceDetailRoute,
    init() {
      this.pageLoading = true;
      this.$store
        .dispatch('fetchFamilyTreeClanMetaAction', {familyTreeId: this.treeId, surname: this.clanName})
        .catch(() => {
          this.pageNotFound = true;
        })
        .finally(() => {
          this.pageLoading = false;
        });
      const payload = {only_count: true, category_id: sourceTypeCategories.CATEGORY_ZUPUS, clan_name: this.clanName};
      this.$store.dispatch('searchSourcesInBackgroundAction', payload).then(response => {
        this.zupusCount = response.meta.total_count;
      });
      this.$store.dispatch('fetchFamilyTreeDetailsAction', this.treeId);
      this.fetchSavedZupus();
    },
    onShareMembersChange(newList) {
      this.$store.commit('mutateFamilyTreeDetailsState', {tree_members: newList});
    },
    onSharePublicChange(value) {
      this.$store.commit('mutateFamilyTreeDetailsState', {is_public: value});
    },
    fetchSavedZupus(page) {
      const params = {page: page || 1, family_tree_id: this.treeId, clan_name: this.clanName};
      this.$store.dispatch('fetchSavedClanPedigreesAction', params);
    },
  },
  name: 'FamilyTreeClanPage',
  components: {
    AboutZupus,
    BasePagination,
    FamilytreeSharingPopover,
    StandOutBlock,
    PageNotFound,
    ChevronLeftIcon,
    RelativeItem,
    MyZupuItem,
  },
};
</script>

<style scoped lang="scss">
.family-tree-clan-page {
  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    a {
      display: flex;
      align-items: center;
    }

    .family-tree-sharing-popover::v-deep {
      .control-button {
        color: $primary-600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .heading-block {
    padding: 0;

    .clan-data-container {
      padding: 24px;
      display: flex;
      align-items: self-start;
      justify-content: space-between;
    }

    h5 {
      margin: 0;
    }
    .sub-title {
      margin-top: 8px;
      color: $neutral-500;
    }
  }

  .item-block {
    margin-top: 24px;
    border-radius: 4px;
    background: $background-light;

    .header {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $neutral-200;
      .heading-6 {
        color: $neutral-600;
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .content {
      padding: 20px 24px;
      .text {
        margin-top: 8px;
      }
      .whatszupu {
        margin-top: 16px;
      }
      .places-list + .bold {
        margin-top: 12px;
      }
    }
  }
  .item-block.zupus-item-block {
    .content {
      padding-top: 0;
      padding-bottom: 0;
    }
    .pagination {
      margin-top: 0;
      padding-bottom: 24px;
    }
  }

  .persons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .heading-block {
      .clan-data-container {
        padding: 24px 16px;
        flex-direction: column;
        align-items: stretch;
      }
    }
    .item-block {
      .content {
        padding: 16px;
      }
    }
    .persons-item-block .content {
      padding: 16px 0;
    }
    .persons {
      display: block;

      .relative-item-wrapper:not(:last-child) {
        .relative-item {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
