import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {isChineseText} from '@common/utils/utils';

import {
  PARENT_RELATION_TYPE,
  SIBLING_RELATION_TYPE,
  SPOUSE_RELATION_TYPE,
  CHILD_RELATION_TYPE,
  UNKNOWN_NAME,
  RELATION_TYPE_TO_CONTROLLER_TYPE,
} from '../constants';

export async function createGhostParentHandler(formData, child, siblingIds, openDetails, vm) {
  return await createRelativeHandler(
    formData,
    PARENT_RELATION_TYPE,
    child,
    null,
    siblingIds,
    [],
    openDetails,
    true,
    vm
  );
}

export async function createRelativeHandler(
  formData,
  relationType,
  person,
  relationMetaInfo,
  childrenIds,
  parentsIds,
  openDetails,
  refetchTree,
  vm
) {
  let created = formData.object_id ? formData : await vm.$store.dispatch('createFamilyTreePersonAction', formData);

  await createRelation(relationType, person, created, relationMetaInfo, childrenIds, parentsIds, vm);

  if (person.has_hidden_subtree) {
    // make sure relatives are re-fetched as they are not requested in the `/diff/`
    vm.$store.commit('clearFamilyTreePersonRelativesState', [person.object_id]);
  }
  if (refetchTree) {
    const controlData = getExpandCreatedPerson(relationType, person, parentsIds, vm);

    await vm.$store.dispatch('fetchFamilyTreeMapDiffAction', {
      id: vm.$store.getters.familyTreeDetailsIdState,
      start_person_id: vm.$store.getters.familyTreeStartPersonIdState,
      map_hash: vm.$store.getters.familyTreeMapImageHashState,
      control_data: controlData,
    });
    vm.$store.commit('setFamilyTreeFocusPersonIdState', created.object_id);
  } else {
    vm.$store.commit('setFamilyTreePersonsDrawnState', []);
    vm.$store.commit('setFamilyTreeLinesDrawnState', []);
  }
}

export async function createRelation(
  relationType,
  person,
  createdPerson,
  relationMetaInfo,
  childrenIds,
  parentsIds,
  vm
) {
  const methodsMapping = {
    [PARENT_RELATION_TYPE]: createParentRelation,
    [CHILD_RELATION_TYPE]: createChildRelation,
    [SIBLING_RELATION_TYPE]: createSiblingRelation,
    [SPOUSE_RELATION_TYPE]: createSpouseRelation,
  };
  return methodsMapping[relationType](person, createdPerson, relationMetaInfo, childrenIds, parentsIds, vm);
}
function createParentRelation(person, createdPerson, relationMetaInfo, childrenIds, parentsIds, vm) {
  let personId = person.object_id;
  const personParents = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).parents || [];
  const parentsId = personParents.map(p => p.object_id);
  const parentIds =
    parentsIds && parentsIds.length
      ? parentsIds
      : parentsId.length
      ? [createdPerson.object_id, parentsId[0]]
      : [createdPerson.object_id];
  return vm.$store.dispatch('createParenthoodAction', {
    parent_ids: parentIds,
    child_ids: [personId, ...childrenIds],
  });
}
function createChildRelation(person, createdPerson, relationMetaInfo, childrenIds, parentsIds, vm) {
  parentsIds = parentsIds.length ? parentsIds : [person.object_id];
  return vm.$store.dispatch('createParenthoodAction', {
    parent_ids: parentsIds,
    child_ids: [createdPerson.object_id],
  });
}
async function createSiblingRelation(person, createdPerson, relationMetaInfo, childrenIds, parentsIds, vm) {
  const personParents = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).parents || [];
  const parentsId = personParents.map(p => p.object_id);
  let parentIds = parentsId.slice(0, 2);
  let childIds = [createdPerson.object_id];
  if (!parentIds.length) {
    AnalyticsMainHandler.trackTreeAddSiblingWithoutParentEvent();
    const parentData = {
      gender: 'm',
      hierarchy_level: getNewPersonHierarchyLevelByRelationType(person.hierarchy_level, PARENT_RELATION_TYPE),
      generation_number: getNewPersonGenerationNumberByRelationType(person.generation_number, PARENT_RELATION_TYPE),
      family_tree_id: person.family_tree_id,
      is_deceased: null,
    };
    const unknownParent = await vm.$store.dispatch('createFamilyTreePersonAction', parentData);
    childIds.push(person.object_id);
    parentIds = [unknownParent.object_id];
  }
  return vm.$store.dispatch('createParenthoodAction', {
    parent_ids: parentIds,
    child_ids: childIds,
  });
}
function createSpouseRelation(person, createdPerson, relationMetaInfo, childrenIds, parentsIds, vm) {
  let personId = person.object_id;
  if (childrenIds.length) {
    const parentIds = [personId, createdPerson.object_id];
    return vm.$store.dispatch('createParenthoodAction', {parent_ids: parentIds, child_ids: childrenIds});
  }
  const personsData = {person1_id: personId, person2_id: createdPerson.object_id};
  const payload = relationMetaInfo ? {...personsData, ...relationMetaInfo.marriage_data} : personsData;
  return vm.$store.dispatch('createCoupleAction', payload);
}
function getExpandCreatedPerson(relationType, person, parentsIds, vm) {
  const controllerType = RELATION_TYPE_TO_CONTROLLER_TYPE[relationType];
  if (!controllerType) {
    return [];
  }

  let persons = [person];
  if (parentsIds && parentsIds.length) {
    /* after a child was added to a couple */
    persons = parentsIds.filter(id => !!id).map(id => vm.$store.getters.familyTreePersonsByIdState[id]);
  }
  let controllerIds = [];
  for (let p of persons) {
    if (p && p.controller_ids && p.controller_ids.length) {
      controllerIds.push(...p.controller_ids);
    }
  }
  if (!controllerIds.length) {
    return [];
  }
  return [
    {
      controller_id: controllerIds,
      controller_type: controllerType,
      controller_state: true,
    },
  ];
}

export function getFirstEnCnSurnames(surnames) {
  const en = surnames && surnames.length ? surnames.find(item => !isChineseText(item.value)) : null;
  const cn = surnames && surnames.length ? surnames.find(item => isChineseText(item.value)) : null;
  let res = en && en.value ? [en] : [];
  if (cn && cn.value) {
    res.push(cn);
  }
  return res;
}

export function getNewPersonSurnameByRelationType(relationType, person, parentsIds, vm) {
  if (relationType === SIBLING_RELATION_TYPE) {
    return getFirstEnCnSurnames(person.surnames);
  }
  if (relationType === CHILD_RELATION_TYPE) {
    const otherParentId = parentsIds.find(id => id !== person.object_id);
    const drawn = vm.$store.getters.familyTreePersonsByIdState;
    const spouses = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).spouses;
    const otherParent = otherParentId
      ? drawn[otherParentId] || spouses.find(sp => sp.object_id === otherParentId)
      : null;
    const father = otherParent && otherParent.gender === 'm' ? otherParent : person.gender === 'm' ? person : null;
    return father ? getFirstEnCnSurnames(father.surnames) : null;
  }
}
export function getNewPersonHierarchyLevelByRelationType(hierarchyLevel, relationType) {
  if (relationType === PARENT_RELATION_TYPE) return hierarchyLevel - 1;
  if (relationType === CHILD_RELATION_TYPE) return hierarchyLevel + 1;
  return hierarchyLevel;
}
export function getNewPersonGenerationNumberByRelationType(generationLevel, relationType) {
  if (!generationLevel && generationLevel !== 0) return;
  if (relationType === PARENT_RELATION_TYPE) return generationLevel - 1;
  if (relationType === CHILD_RELATION_TYPE) return generationLevel + 1;
  if (relationType === SIBLING_RELATION_TYPE) return generationLevel;
}
export function getNewPersonGender(relationType, person, vm) {
  const getOppositeGender = gender => (gender === 'm' ? 'f' : 'm');
  const personParents = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).parents || [];
  if (relationType === PARENT_RELATION_TYPE && personParents.length === 1 && personParents[0].gender) {
    return getOppositeGender(personParents[0].gender);
  }
  if (relationType === SPOUSE_RELATION_TYPE && person.gender) {
    return getOppositeGender(person.gender);
  }
}

export function getPossibleChildrenList(relationType, person, vm) {
  if (relationType === PARENT_RELATION_TYPE) {
    const personSiblings = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).siblings || [];
    return personSiblings.map(relative => {
      return getPossibleChild(relative);
    });
  }
  if (relationType === SPOUSE_RELATION_TYPE) {
    const personChildren = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).children || [];
    return personChildren.map(relative => {
      return getPossibleChild(relative);
    });
  }
  return [];
}
function getPossibleChild(relative) {
  let child = {id: relative.object_id, text: `${relative.full_name || UNKNOWN_NAME}`};
  if (relative.parents_ids && relative.parents_ids.length < 2) {
    child.selected = true;
  }
  return child;
}
export function getPossibleParentsList(relationType, person, vm) {
  const personSpouses = vm.$store.getters.getFamilyTreePersonRelativesState(person.object_id).spouses || [];
  if (relationType === CHILD_RELATION_TYPE && personSpouses.length) {
    let possibleParents = personSpouses.map(relative => {
      return getPossibleParent(relative, person);
    });
    possibleParents[0].selected = true;
    possibleParents.push({id: [person.object_id], text: `${person.full_name || UNKNOWN_NAME} and Other Parent`});
    return possibleParents;
  }
  return [];
}
function getPossibleParent(relative, person) {
  return {
    id: [relative.object_id, person.object_id],
    text: `${person.full_name || UNKNOWN_NAME} and ${relative.full_name || UNKNOWN_NAME}`,
  };
}
