<template>
  <div class="family-tree-landing-page">
    <header>
      <h1 class="extended">Grow Your Family Tree Effortlessly with Record Matches</h1>
      <p class="text-lg">
        Start with what you know and let our RecordFinder™ do the rest. We'll automatically find matching records as you
        grow your tree.
      </p>
      <mcr-buttons-row>
        <mcr-button-router-link
          :to="toRegister"
          @click="trackPreRegisterAction"
          :label="registerLabel"
        ></mcr-button-router-link>
      </mcr-buttons-row>
      <p class="login-link"><router-link :to="ftDemoRoute">View Demo</router-link></p>
      <img
        width="880"
        height="500"
        :data-src="$getAsset('/assets/services/family-tree-software-cropped.png')"
        class="header-splash lazyload"
      />
    </header>
    <div class="image_panel">
      <div class="image_panel_text">
        <h5>Full Chinese Support. 100% Free.</h5>
        <p>The first family tree builder designed for&mdash; and by&mdash; Chinese genealogists.</p>
        <ul>
          <li>Support for <strong class="feature-highlight">Chinese and English names</strong></li>
          <li>Add <strong class="feature-highlight">multiple spouses</strong> to one ancestor</li>
          <li>Get <strong class="feature-highlight">translation suggestions</strong> for Chinese surnames</li>
          <li>
            Add <strong class="feature-highlight">generation names</strong> and
            <strong class="feature-highlight">paper names</strong>
          </li>
          <li>Explore the <strong class="feature-highlight">Chinese clans</strong> of your family</li>
        </ul>
        <mcr-button-router-link class="inline" :to="ftDemoRoute">Explore a Live Demo &#10142;</mcr-button-router-link>
      </div>
      <div class="image_panel_image">
        <img width="600" height="660" :data-src="$getAsset('/assets/services/tree/profile.jpg')" class="lazyload" />
      </div>
    </div>
    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>Preserve memories with photos and files.</h5>
        <ul>
          <li>Attach photos or documents to any member of your family tree</li>
          <li>Add notes to a file or photo to tell its full story</li>
          <li>Search and organize with <strong class="feature-highlight">Our Library Suite</strong></li>
        </ul>
      </div>
      <div class="image_panel_image">
        <img width="600" height="375" :data-src="$getAsset('/assets/services/tree/gallery.jpg')" class="lazyload" />
      </div>
    </div>
    <div class="image_panel">
      <div class="image_panel_text">
        <h5>Get record matches.</h5>
        <p>
          As you add names and surnames to your tree, we’ll let you know if we find any matches of relevance to your
          family.
        </p>
        <p>
          Powered by <strong class="feature-highlight">Our Roots Database</strong>, the largest collection of digitized
          genealogical records for overseas Chinese in the world.
        </p>
      </div>
      <div class="image_panel_image">
        <img width="600" height="385" :data-src="$getAsset('/assets/services/tree/records.jpg')" class="lazyload" />
      </div>
    </div>
    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>See the bigger picture.</h5>
        <p>
          Look at a direct line to your past with the lineage view. Featuring
          <strong class="feature-highlight">generation number</strong> support from Family Tree Records.
        </p>
        <p class="supplemental">
          Coming Soon: Migration maps and family events in an interactive map!
          <router-link :to="toRegister">Register now</router-link> to be notified when it goes live.
        </p>
      </div>
      <div class="image_panel_image">
        <img
          width="600"
          height="500"
          :data-src="$getAsset('/assets/services/tree/lineage-noshadow.jpg')"
          class="in-layer lazyload"
        />
      </div>
    </div>
    <div class="readable_content additional_panel">
      <h5 class="action-title">Start exploring your roots today with our tree builder!</h5>
      <mcr-buttons-row>
        <mcr-button-router-link
          :to="toRegister"
          @click="trackPreRegisterAction"
          :label="registerLabel"
        ></mcr-button-router-link>
      </mcr-buttons-row>
    </div>
    <mcr-footer></mcr-footer>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import mcrFooter from '@/components/common/mcrFooter';

import {DEMO_FAMILY_TREE_ID} from './constants';

export default {
  metaInfo: {
    title: 'Family Tree',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Free family tree builder. Add your family members and start exploring your roots today. Built for- and by- people of Chinese descent.',
      },
      {
        vmid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: 'https://cdn.mychinaroots.com/src/assets/services/tree_new.png',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'https://cdn.mychinaroots.com/src/assets/services/tree_new.png',
      },
    ],
  },
  data() {
    return {
      route: {name: 'familytree-details-my'},
      registerLabel: 'Get Started for Free',
    };
  },
  computed: {
    toRegister() {
      let data = this.$router.resolve(this.route);
      return {name: 'register', query: {redirect: data.href}};
    },
    toLogin() {
      let data = this.$router.resolve(this.route);
      return {name: 'login', query: {redirect: data.href}};
    },
    ftDemoRoute() {
      return {name: 'familytree-details', params: {id: DEMO_FAMILY_TREE_ID}};
    },
  },
  methods: {
    trackPreRegisterAction() {
      AnalyticsMainHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
  },
  components: {mcrButtonsRow, McrButtonRouterLink, mcrFooter},
};
</script>

<style lang="scss" scoped>
header {
  padding-bottom: 20px;
  .login-link {
    margin-bottom: 0;
  }
  p {
    text-align: center;
    color: $neutral-600;
  }
}

.tree-summary {
  text-align: center;
}

h5.action-title {
  text-align: center;
}

.additional_panel {
  border-top: 1px solid $divider-line-color;
}
img {
  object-fit: contain;
}
</style>
