<template>
  <div class="person-quick-edit with-sticky-buttons">
    <transition name="fade">
      <drag-drop-prompt @drop="onDrop"></drag-drop-prompt>
    </transition>
    <div class="heading">
      <div class="text-lg bold">Editing {{ fullName }}</div>
      <close-icon @click="$emit('close')"></close-icon>
    </div>

    <div class="content">
      <person-quick-form
        ref="form"
        :form-initial-data="initialData"
        :gender-options="familyTreePersonOptionsState.genders"
        @keyup.enter.native="save"
      >
        <div slot="extra-fields">
          <edit-picture ref="picture" :profile-picture="initialData.profile_picture"></edit-picture>
        </div>
      </person-quick-form>
    </div>

    <div class="action-buttons">
      <a class="more-options-link" @click="openFullForm">More options</a>
      <mcr-button class="save-button" :loading="formLoading" @click="save">Save</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

import EditPicture from '@/components/modules/familyTree/tree/modals/quick/EditPicture';
import PersonQuickForm from '@/components/modules/familyTree/tree/modals/quick/PersonQuickForm';

export default {
  props: {
    fullName: String,
    initialData: Object,
    saveClickHandler: Function,
    fullFormOpener: Function,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'familyTreePersonUpdateLoadingState',
      'familyTreePersonProfilePictureLoadingState',
      'familyTreePersonOptionsState',
      'familyTreePhotosByPersonIdState',
    ]),
    formLoading() {
      const personPhotos = this.familyTreePhotosByPersonIdState[this.initialData.id];
      const photoUpload = personPhotos && personPhotos[0] && personPhotos[0].uploading;
      return this.familyTreePersonUpdateLoadingState || this.familyTreePersonProfilePictureLoadingState || photoUpload;
    },
  },
  methods: {
    save() {
      if (this.formLoading) {
        return;
      }
      const personData = this.$refs.form.getPersonForSave();
      const profilePictureData = this.$refs.picture.getDataForSave();
      this.saveClickHandler(personData, profilePictureData);
    },
    openFullForm() {
      const personData = this.$refs.form.getPersonForSave();
      this.$emit('close');
      this.fullFormOpener(personData);
    },
    onDrop(files) {
      if (files.length > 0) {
        this.$refs.picture.filesSelected(files);
      }
    },
  },
  components: {DragDropPrompt, EditPicture, McrButton, PersonQuickForm, CloseIcon},
};
</script>

<style lang="scss" scoped>
.person-quick-edit {
  .extra-select {
    margin-bottom: 30px;
  }

  .drag-prompt {
    justify-content: flex-start;
    padding-top: 100px;
  }

  .action-buttons {
    display: flex;
    align-items: center;

    .save-button {
      margin-left: 25px;
      flex-grow: 1;
    }
  }
  @media only screen and (min-width: $breakpoint-phablet) {
    min-width: 560px;
  }
}
</style>
